.wrap
  min-height: 100vh
  min-height: var(--tg-viewport-stable-height)
  display: flex
  flex-direction: column

.cont
  flex: 1
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: 32px
  box-sizing: border-box

.photos
  width: 220px
  height: 220px
  position: relative
  flex: 0 0 auto

.photosIcon
  position: absolute
  width: 104px
  height: 104px
  border-radius: 16px
  background: url('./icon.svg') no-repeat center center
  background-size: contain
  z-index: 3
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)

.photo
  position: absolute
  width: 120px
  height: 192px
  border-radius: 16px
  background: var(--background-tertiary) no-repeat center/cover

.photoFirst
  left: 0
  top: 0
  transform: rotate(-8deg)
  z-index: 2

.photoLast
  right: 0
  bottom: 0
  transform: rotate(8deg)
  z-index: 1

.title
  font-size: 26px
  font-weight: 800
  line-height: 32px
  margin-top: 32px

.description
  font-size: 17px
  font-weight: 400
  line-height: 24px
  text-align: center
  margin-top: 8px

.footer
  flex: 0 0 auto
  padding: 16px

