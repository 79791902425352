.inviteLink
  font-size: 14px
  line-height: 16px
  color: var(--text-color)
  background: var(--secondary-bg-color)
  border-radius: 8px
  padding: 8px 16px
  margin-top: 16px

.balanceWrap
  padding: 16px 16px 0

.balance
  padding: 16px
  background: var(--secondary-bg-color)
  border-radius: 16px

.balanceLabel
  font-size: 14px
  color: var(--hint-color)
  line-height: 16px

.balanceAmount
  font-size: 28px
  font-weight: bold
  line-height: 32px
  margin-top: 6px

.balanceHint
  font-size: 14px
  color: var(--hint-color)
  line-height: 16px
  margin-top: 6px
  margin-left: 16px

.bannerImage
  width: 42px
  height: 42px
  border-radius: 12px
  background: url('logo.jpeg') no-repeat center/cover
