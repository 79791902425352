.Card
  background: #000
  flex: 1
  position: relative
  display: flex
  overflow: hidden

  .hasNotch &
    border-radius: 36px

.Card_photos
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 1

.Card_photos__pagination
  position: absolute
  top: 0
  bottom: 0
  z-index: 6

  &.prev
    width: 40%
    left: 0

  &.next
    width: 60%
    right: 0

.Card_photo
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: no-repeat center/cover
  z-index: 1
  opacity: 0

  &.active
    opacity: 1

.Card__scrim_top, .Card__info_wrap:after
  position: absolute
  left: 0
  width: 100%
  min-height: 160px
  z-index: 4
  opacity: 0.56

.Card__scrim_top
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)
  top: 0
  transition: opacity 150ms ease-in-out

  .ui_hidden &
    opacity: 0

.Card__content
  display: flex
  flex: 1
  flex-direction: column
  position: relative
  z-index: 5
  justify-content: space-between
  overflow: hidden

.Card__info_wrap
  width: 100%
  padding: 16px
  box-sizing: border-box
  transition: opacity 150ms ease-in-out
  z-index: 10
  position: absolute
  bottom: 0
  left: 0
  right: 0
  //padding-bottom: 56px + 12
  //padding-bottom: calc(68px + env(safe-area-inset-bottom))

  .Card.custom &
    padding-top: 52px

  .ui_hidden &
    opacity: 0

  .desktop &
    //padding-bottom: 16px
    //padding-bottom: calc(16px + env(safe-area-inset-bottom))

.Card__info_wrap_fixPadding
  //padding-bottom: 68px + 20px
  //padding-bottom: calc(88px + env(safe-area-inset-bottom))

.Card__info_icon
  flex: 0 0 auto
  color: #fff
  margin-left: 16px
  align-self: flex-start
  margin-top: 4px
  transform: rotate(180deg)

.Card__info_wrap:after
  content: ''
  bottom: 0
  opacity: 1
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .72) 100%)
  z-index: 1
  height: 360px
  box-sizing: border-box
  pointer-events: none

  .Card.isLike &
    background: linear-gradient(180deg, rgba(65, 166, 217, 0) 0%, #E64588 100%)
    opacity: 0.4

  .Card.isSuperLike &
    background: linear-gradient(180deg, rgba(65, 166, 217, 0) 0%, #41A6D9 100%)
    opacity: 0.4

  .Card.custom &
    height: 100%
    top: inherit
    min-height: auto !important

.Card__info
  position: relative
  z-index: 2
  color: var(--constant-light)
  display: flex
  align-items: flex-end
  flex: 1
  overflow: hidden

.Card__info__content
  flex: 1
  overflow: hidden
  transition: all 150ms ease-in-out

  &:active
    opacity: var(--active-opaicty)

.Card__info__name_wrap
  display: flex
  align-items: center

.Card__info__name
  font-weight: 800
  font-size: 26px
  line-height: 32px
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.Card__info__premium
  flex: 0 0 auto
  margin-left: 8px
  color: var(--link-color)

  &, svg
    width: 26px
    height: 26px

.Card__info__caption
  font-size: 17px
  line-height: 24px
  margin-top: 4px

.Card__info__caption__item
  -webkit-box-orient: vertical
  display: -webkit-box
  text-overflow: ellipsis
  overflow: hidden

  &.single_line
    -webkit-line-clamp: 1
    max-height: 24px

  &.three_lines
    -webkit-line-clamp: 3
    max-height: 72px

.Card__info__buttons
  flex: 0 0 auto
  margin-left: 16px
  width: 56px
  display: flex
  flex-direction: column
  align-items: flex-end
  min-height: 10px

.Card__info__buttons__icon
  width: 28px
  height: 28px
  line-height: 28px
  transition: opacity 150ms ease-in-out

  svg
    pointer-events: none

  &:active
    opacity: var(--active-opaicty)

.Card__info__pills_wrap
  overflow: hidden
  max-height: 72px
  margin-top: 8px

.Card__photo_indicator
  width: 100%
  margin-top: 24px
  margin-top: calc(24px + env(safe-area-inset-top))
  display: flex
  justify-content: center
  transition: opacity 150ms ease-in-out

  .ui_hidden &
    opacity: 0

  .desktop &
    margin-top: 24px

.Card__photo_indicator__item
  background: rgba(255, 255, 255, 0.24)
  height: 3px
  width: 8px
  border-radius: 2px
  position: relative
  overflow: hidden
  margin-left: 8px
  transition: width 150ms ease-in-out

  &.active
    width: 24px

.Card__photo_indicator__item_progress
  background: #fff
  border-radius: 2px
  position: absolute
  top: 0
  left: -24px
  height: 100%
  width: 100%
  transform: translateX(0)

  .Card__photo_indicator__item.filled &
    transform: translateX(100%)

.Card__badge
  height: 32px
  align-items: center
  padding: 0 12px
  border-radius: 16px
  margin-top: 12px
  font-weight: bold
  font-size: 16px
  line-height: 20px
  display: inline-flex

  &.super_like
    background: #3380CC
    background: var(--gradient-blue)

  &.like
    background: #FF4070
    background: var(--gradient-pink)

.Card__badge__icon
  content: ''
  display: block
  margin-right: 8px

  &, svg
    width: 16px
    height: 16px

.Card__info_actions
  position: relative
  z-index: 2
  margin-left: 16px
  flex: 0 0 auto
  display: flex
  align-items: center
  justify-content: center
  margin-top: 16px

.Card__info_action
  width: 42px
  height: 42px
  border-radius: 50%
  background: #fff
  display: flex
  align-items: center
  justify-content: center
  color: var(--tint-blue)
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.08)
  margin-left: 12px

  &:first-child
    margin-left: 0

  &.large
    width: 56px
    height: 56px

  &.like
    color: var(--tint-like)
    border-color: var(--tint-like)

  &.dislike
    color: var(--tint-dislike)
    border-color: var(--tint-dislike)

  &.cancel
    color: var(--tint-gold)
    border-color: var(--tint-gold)

  &.disabled
    color: #71737A
    border-color: #71737A
    pointer-events: none

  svg
    width: 21px
    height: 21px

  &.large svg
    width: 28px
    height: 28px


.Card__info__looking_for_wrap
  display: flex
  justify-content: flex-start
  padding-top: 4px

.Card__info__looking_for
  position: relative
  border-radius: 16px
  overflow-y: hidden
  display: flex
  align-items: center
  padding: 6px 12px
  background: rgba(255,255,255,0.24)

.Card__info__looking_for__smile
  width: 20px
  height: 20px
  line-height: 20px
  font-size: 20px

.Card__info__looking_for__text
  font-size: 14px
  line-height: 16px
  color: #fff
  font-weight: bold
  margin-left: 8px

.Card__bottom_padding
  height: 56px
  flex: 0 0 auto
  width: 56px

.Card__location
  display: flex
  align-items: center
  font-size: 17px
  line-height: 24px

  svg
    flex: 0 0 auto
    width: 18px
    height: 18px
    margin-right: 6px
    color: #fff
