.placeholderWrap
  padding: 16px 0

.balanceWrap
  padding: 0 16px

.balance
  padding: 16px
  background: var(--secondary-bg-color)
  border-radius: 16px

.balanceLabel
  font-size: 14px
  color: var(--hint-color)
  line-height: 16px

.balanceAmount
  font-size: 28px
  font-weight: bold
  line-height: 32px
  margin-top: 6px

.balanceHint
  font-size: 14px
  color: var(--hint-color)
  line-height: 16px
  margin-top: 6px
  margin-left: 16px

.balanceWithdraw
  margin-top: 8px

.inviteWrap
  padding: 0 16px

.inviteLinkCaption
  font-size: 14px
  color: var(--hint-color)
  line-height: 18px
  padding: 8px 16px

.inviteLink
  padding: 12px
  background: var(--secondary-bg-color)
  border-radius: 12px
  margin-top: 12px

.inviteLinkLabel
  font-size: 14px
  color: var(--hint-color)
  line-height: 16px

.inviteLinkValue
  font-size: 16px
  font-weight: bold
  line-height: 20px
  margin-top: 2px

.inviteLinkCopy
  margin-top: 12px

.withdrawWrap
  padding: 0 16px 16px

.withdraw
  font-size: 16px
  line-height: 20px

  span
    color: var(--link-color)
    font-weight: bold
