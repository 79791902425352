.photos
  display: flex

.photosAdded
  display: flex
  margin-left: 8px

  &:first-child
    margin-left: 0

.photo
  flex: 0 0 auto
  width: 90px
  height: 140px
  border-radius: 12px
  margin-left: 8px
  background: var(--secondary-bg-color) no-repeat center/cover
  display: flex
  align-items: center
  justify-content: center
  color: var(--accent)
  position: relative
  overflow: hidden

  input
    position: absolute
    z-index: 10
    top: 0
    left: 0
    width: 100%
    height: 100%
    opacity: 0

  &:first-child
    margin-left: 0

.photoCloseWrap
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  top: 0
  right: 0
  width: 32px
  height: 32px

.photoClose
  width: 20px
  height: 20px
  color: #fff
  background: rgba(0,0,0,.7)
  border-radius: 8px
  display: flex
  align-items: center
  justify-content: center
