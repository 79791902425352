.header
  padding: 8px 16px
  font-size: 26px
  font-weight: 800
  line-height: 32px
  margin-top: 16px

  &:first-child
    margin-top: 0

