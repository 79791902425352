.wrap
  display: flex
  flex-direction: column
  flex: 1
  height: var(--tg-viewport-stable-height)
  max-height: var(--tg-viewport-stable-height)

.content
  flex: 1
  display: flex
  flex-direction: column
  overflow: hidden

.tabs
  display: flex
  //position: fixed
  //z-index: 1000
  //bottom: 0
  //left: 0
  //right: 0
  flex: 0 0 auto
  background: var(--bg-color)
  padding-bottom: #{env(safe-area-inset-bottom)}

.fixPadding .tabs
  padding-bottom: 20px
  padding-bottom: max(#{env(safe-area-inset-bottom)}, 20px)

.wrapTransparent .tabs
  background: transparent
  //position: fixed
  //z-index: 1000
  //bottom: 0
  //left: 0
  //right: 0

.tab
  height: 56px
  flex: 1
  display: flex
  align-items: center
  justify-content: center
  color: var(--foreground-tertiary)

.tabIcon
  position: relative
  width: 28px
  height: 28px

.tabActive
  color: var(--link-color)

.wrapTransparent .tabActive
  color: #fff

.tabsHelper
  height: 56px
  flex: 0 0 auto
  width: 56px

.wrapTransparent .tabsHelper
  display: none

.tabBadge
  width: 12px
  height: 12px
  background: red
  border-radius: 50%
  position: absolute
  top: 0
  right: -4px
  border: 2px solid var(--secondary-bg-color)
  z-index: 10
