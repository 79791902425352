.wrap
  height: 42px
  display: flex

.back
  width: 42px
  height: 42px
  display: flex
  align-items: center
  justify-content: center
  margin-left: 8px
