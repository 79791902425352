\:root
  // Telegram
  --bg-color: var(--tg-theme-bg-color, #ffffff)
  --section-bg-color: var(--tg-theme-section-bg-color, var(--bg-color))
  --text-color: var(--tg-theme-text-color, #000000)
  --hint-color: var(--tg-theme-hint-color, #8e8e93)
  --subtitle-text-color: var(--tg-theme-subtitle-text-color, var(--hint-color))
  --link-color: var(--tg-theme-link-color, #007aff)
  --accent-text-color: var(--tg-theme-accent-text-color, var(--link-color))
  --button-text-color: var(--tg-theme-button-text-color, #ffffff)
  --button-color: var(--tg-theme-button-color, #007AFF)
  --secondary-bg-color: var(--tg-theme-secondary-bg-color, #efeff4)
  --destructive-text-color: var(--tg-theme-destructive-text-color, #ff3b30)
  --section-header-text-color: var(--tg-theme-section-header-text-color, #6d6d72)
  --separator-color: #C8C7CB

  --foreground-primary: #0B0D14
  --foreground-secondary: #71737A
  --foreground-tertiary: #B0B2B8
  --background-primary: #FFFFFF
  --background-secondary: #FFFFFF
  --background-tertiary: rgba(206, 208, 214, 0.24)
  --background-quaternary: rgba(169, 173, 184, 0.24)
  --border: rgba(0, 0, 0, 0.08)
  --shadow: rgba(243, 244, 245, 0)
  --overlay-medium: rgba(0, 0, 0, 0.48)
  --tint: var(--link-color)
  --tint-purple: #7341D8
  --tint-gold: #FFA526
  --dark-button: #000
  --dark-button-color: #fff

  --constant-dark: #000000
  --constant-light: #FFFFFF

  --tint-like: #45E688
  --tint-dislike: #FF4070
  --overlay-heavy: rgba(0, 0, 0, 0.84)
  --overlay-light: rgba(0, 0, 0, 0.16)
  --tint-blue: #3380CC

  --gradient-purple: linear-gradient(137.61deg, #4830BF 0%, #A455F2 100%)
  --gradient-pink:  linear-gradient(137.61deg, #E64588 0%, #FF66BF 100%)
  --gradient-blue:  linear-gradient(137.61deg, #3380CC 0%, #55D8F2 100%)
  --gradient-gold:  linear-gradient(137.61deg, #F27318 0%, #FFCC33 100%)

body[scheme="space_gray"]
  --foreground-primary: #fff
  --foreground-secondary: #898B8F
  --foreground-tertiary: #4E4F52
  --background-primary: #000
  --background-secondary: #1C1D1F
  --background-tertiary: rgba(79, 80, 82, 0.32)
  --background-quaternary: rgba(117, 118, 122, 0.32)
  --border: rgba(0, 0, 0, 0.16)
  --shadow: rgba(25, 25, 26, 0)
  --overlay-medium: rgba(0, 0, 0, 0.64)
  --tint-purple: #8250E5
  --tint-gold: #FFAF40
  --dark-button: #fff
  --dark-button-color: #000
  --separator-color: #3D3D3F

body > iframe[style^="position: fixed;"]
  pointer-events: none

body
  background: var(--bg-color) !important
  box-sizing: border-box

.vkuiPanelHeaderButton--ios .vkuiIcon--28
  padding: 0 !important

.vkuiPanelHeader:before, .vkuiPanelHeader__in
  padding-top: 0 !important

.vkuiFixedLayout--vertical-top
  background: var(--secondary-bg-color)
  top: 0 !important
  padding-top: #{var(--vkui_internal--safe_area_inset_top)} !important

body, input, textarea
  font-family: Nunito !important

.vkuiFormField--mode-default
  background: var(--secondary-bg-color) !important

body, html
  height: var(--tg-viewport-stable-height) !important
  min-height: var(--tg-viewport-stable-height) !important
  color: var(--text-color)

.vkuiAppRoot, .vkui__root
  height: inherit

body, html
  padding: 0
  margin: 0
  overflow: hidden !important

*
  box-sizing: border-box
  -webkit-tap-highlight-color: rgba(0,0,0,0)
  -webkit-user-select: none
  -moz-user-select: none
  user-select: none

input, textarea
  user-select: auto
  color: var(--text-color)

#root
  display: flex
  flex-direction: column
  flex: 1
