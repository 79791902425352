.dialog
  padding: 8px 16px
  display: flex
  align-items: center

.dialogPhoto
  width: 72px
  height: 72px
  border-radius: 50%
  background: var(--bg-color) no-repeat center/cover
  flex: 0 0 auto

.dialogCont
  margin-left: 16px
  flex: 1
  overflow: hidden

.dialogName
  font-size: 17px
  font-weight: 700
  line-height: 24px
  color: var(--text-color)

.dialogText
  font-size: 17px
  font-weight: 400
  line-height: 24px
  color: var(--hint-color)
  margin-top: 2px
  overflow: hidden
  max-width: 100%
  text-overflow: ellipsis
  white-space: nowrap

  &:empty
    display: none

.dialogBadge
  height: 24px
  line-height: 24px
  text-align: center
  font-size: 12px
  font-weight: 800
  min-width: 24px
  background: var(--button-color)
  color: var(--button-text-color)
  padding: 0 8px
  border-radius: 12px
  margin-left: 8px
  flex: 0 0 auto
  box-sizing: border-box
