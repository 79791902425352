.CardsSwiper
  flex: 1
  overflow: hidden
  position: relative
  margin: 0
  background: #000

.CardsSwiper__disable_left_side
  position: absolute
  z-index: 5
  left: 0
  width: 25px
  height: 100%

.CardsSwiper__card
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  display: flex
  z-index: 2

  .ui_hidden &
    pointer-events: none

  &.secondary
    z-index: 1
    pointer-events: none

.CardsSwiper__action_badge
  border: 6px solid #45E688
  border-radius: 24px
  transform: rotate(-12deg)
  font-size: 48px
  line-height: 72px
  font-weight: bold
  padding: 0 32px
  position: absolute
  top: 50%
  z-index: 100
  pointer-events: none

  &.like
    border-color: var(--tint-like)
    color: #45E688
    color: var(--tint-like)
    left: 58px

  &.dislike
    border-color: var(--tint-dislike)
    color: #FF4070
    color: var(--tint-dislike)
    right: 58px

.CardsSwiper__controls
  position: absolute
  bottom: 16px
  left: 50%
  transform: translateX(-50%)
  display: flex
  transition: opacity 150ms ease-in-out

  .ui_hidden &
    opacity: 0

.CardsSwiper__control
  width: 56px
  height: 56px
  border-radius: 50%
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08)
  background: var(--constant-light) no-repeat center/contain
  background-size: 28px
  margin-left: 16px
  cursor: pointer
  transition: all 150ms ease-in-out

  &:hover
    opacity: var(--active-opaicty)

  &:active
    transform: scale(0.9)
    opacity: 1

  &:first-child
    margin-left: 0

  //&.like
  //  background-image: url('../../../assets/28/gradient/done_28.svg')
  //
  //&.dislike
  //  background-image: url('../../../assets/28/close_28_gradient.svg')

.CardsSwiper__filters
  position: absolute
  top: 0
  top: env(safe-area-inset-top)
  left: 4px
  width: 48px
  height: 48px
  z-index: 100
  color: var(--constant-light)
  display: flex
  align-items: center
  justify-content: center
  transition: opacity 150ms ease-in-out

  .ui_hidden &
    opacity: 0

  &:active
    opacity: var(--active-opaicty)


.CardsSwiper__action_button
  width: 64px
  height: 64px
  border-radius: 50%
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08)
  background: var(--constant-light)
  position: fixed
  top: 50%
  z-index: 100
  background: #fff no-repeat center/contain
  background-size: 28px
  margin-top: -32px

  //&.like
  //  right: -12px
  //  background-image: url('../../../assets/28/gradient/like_28.svg')
  //
  //&.dislike
  //  left: -12px
  //  background-image: url('../../../assets/28/gradient/dislike_28.svg')
