.ProfileCard__wrap
  flex: 1

.ProfileCard
  flex: 1
  background: #000
  display: flex
  position: relative
  min-height: calc(var(--tg-viewport-stable-height) * 0.8)

.ProfileCard__photos
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 1

.ProfileCard__photo
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: no-repeat center/contain
  opacity: 0

  &.active
    opacity: 1

.ProfileCard__indicators
  position: absolute
  top: 12px
  left: 12px
  right: 12px
  display: flex
  z-index: 2

.ProfileCard__indicators__item
  flex: 1
  margin-left: 4px
  height: 2px
  background: #fff
  opacity: 0.6
  border-radius: 1px

  &.active
    opacity: 1

  &:first-child
    margin-left: 0

.ProfileCard__touch_zones
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 3
  display: flex

.ProfileCard__touch_zones__left
  flex: 0.4

.ProfileCard__touch_zones__right
  flex: 0.6

.ProfileCard__bottom
  z-index: 4
  bottom: 0
  left: 0
  right: 0
  position: absolute
  padding: 16px
  box-sizing: border-box

.ProfileCard__actions
  display: flex
  align-items: center
  justify-content: center

.ProfileCard__actions__item
  width: 56px
  height: 56px
  background: #fff
  border-radius: 50%
  margin-left: 12px
  display: flex
  align-items: center
  justify-content: center
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08)

  &.like
    color: #36D97A

  &.dislike
    color: #FF4070

  &:first-child
    margin-left: 0

.ProfileCard__danger
  padding: 8px 12px
  font-size: 14px
  line-height: 18px
  border: 1px solid var(--tint-dislike)
  border-radius: 8px
  margin-bottom: 12px

.ProfileCard__info
  padding: 16px

.ProfileCard__info__name_wrap
  display: flex
  align-items: center

.ProfileCard__info__name
  font-size: 26px
  font-weight: 800
  line-height: 32px
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

.ProfileCard__info__premium
  color: var(--link-color)
  display: flex
  align-items: center
  position: relative
  padding: 4px 8px
  margin-left: 8px
  flex: 0 0 auto

  &:after
    content: ''
    position: absolute
    background: var(--link-color)
    opacity: 0.2
    top: 0
    left: 0
    right: 0
    bottom: 0
    border-radius: 8px

  svg
    width: 18px
    height: 18px

.ProfileCard__distance
  display: flex
  align-items: center
  font-size: 16px
  line-height: 22px
  color: var(--hint-color)
  margin-top: 2px

  svg
    flex: 0 0 auto
    width: 16px
    height: 16px
    margin-right: 6px

.ProfileCard__info__premium__label
  font-size: 14px
  line-height: 18px
  margin-left: 4px

.ProfileCard__section
  padding: 12px 0

.ProfileCard__section__title
  font-size: 20px
  font-weight: 800
  line-height: 28px

.ProfileCard__section__text
  margin-top: 4px
  font-size: 17px
  font-weight: 400
  line-height: 24px

.ProfileCard__looking_for_wrap
  display: flex
  justify-content: flex-start
  margin-top: 8px

.ProfileCard__looking_for
  padding: 8px 12px
  border-radius: 10px
  color: var(--button-color)
  display: flex
  align-items: center
  position: relative
  overflow: hidden

  &:after
    content: ''
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: var(--button-color)
    z-index: 1
    opacity: 0.08

.ProfileCard__looking_for__smile
  height: 34px
  width: 34px
  flex: 0 0 auto
  display: flex
  align-items: center
  justify-content: center
  line-height: 34px
  text-align: center
  font-size: 30px
  z-index: 2
  position: relative

.ProfileCard__looking_for__cont
  margin-left: 12px
  z-index: 2
  position: relative

.ProfileCard__looking_for__label
  font-size: 12px
  line-height: 14px
  opacity: 0.72
  font-weight: 600

.ProfileCard__looking_for__value
  font-size: 16px
  line-height: 20px
  font-weight: bold
