.container
  padding: 16px 16px 0

.wrap
  background: var(--button-color)
  border-radius: 12px
  padding: 12px
  flex-direction: column
  align-items: center
  display: flex
  text-align: center

.icon
  width: 28px
  height: 28px
  color: var(--button-text-color)

.contWrap
  margin-top: 8px
  display: flex
  align-items: center

.cont
  flex: 1

.title
  font-weight: bold
  font-size: 18px
  line-height: 22px
  color: var(--button-text-color)

.caption
  font-size: 16px
  line-height: 20px
  color: var(--button-text-color)
  opacity: 0.7
  margin-top: 4px

.chevron
  flex: 0 0 auto
  color: var(--button-text-color)
  opacity: 0.7
  width: 16px
  height: 16px
  margin-left: 12px

.button
  border-radius: 16px
  padding: 8px 16px
  font-size: 16px
  line-height: 18px
  background: var(--button-text-color)
  color: var(--button-color)
  font-weight: bold
  margin-top: 16px

.buttonSecondary
  background: transparent
  color: #fff
  margin-top: 8px
