.cell
  display: flex
  min-height: 56px
  align-items: flex-start
  padding: 0 16px
  transition: background 150ms ease-in-out
  text-decoration: none

  &:active
    background: rgba(206, 208, 214, 0.24)
    background: var(--background-tertiary)

.icon
  flex: 0 0 auto
  width: 28px
  color: var(--foreground-tertiary)
  margin-right: 16px
  height: 56px
  display: flex
  align-items: center

  svg
    width: 28px
    height: 28px

.content
  flex: 1
  color: var(--foreground-primary)
  padding: 16px 0
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

.description
  margin-top: 4px
  font-size: 14px
  line-height: 18px
  font-weight: normal
  color: var(--foreground-secondary)
  white-space: normal

.indicator
  flex: 0 0 auto
  color: var(--foreground-secondary)
  height: 56px
  display: flex
  align-items: center
  margin-left: 16px

.content, .indicator
  font-size: 18px
  line-height: 24px

.expandable
  flex: 0 0 auto
  margin-left: 8px
  height: 56px
  color: var(--foreground-tertiary)
  display: flex
  align-items: center
