.header
  padding: 16px
  background: var(--secondary-bg-color)

.headerTitle
  font-size: 20px
  line-height: 24px
  font-weight: bold
  margin-top: 8px

.headerText
  color: var(--hint-color)
  font-size: 14px
  line-height: 18px
  margin-top: 4px

.priceInfo
  font-size: 14px
  line-height: 18px
  padding: 0 16px 16px

.icon
  color: var(--link-color)
  margin: 16px auto 0

  &, svg
    width: 82px
    height: 82px

.title
  font-size: 18px
  line-height: 22px
  font-weight: bold
  text-align: center
  padding: 0 16px
  margin-top: 10px

.rows
  padding: 16px

.rows_in
  border-radius: 12px
  overflow: hidden

.row
  padding: 12px 0
  position: relative

  &:after
    pointer-events: none
    content: ''
    display: block
    position: absolute
    height: 0.5px
    transition: 0.4s ease opacity
    top: 0
    width: 200%
    background: var(--separator-color)

    @media (-webkit-min-device-pixel-ratio: 2),(min-resolution: 2dppx)
      &
        transform: scaleY(0.5)

    @media (-webkit-min-device-pixel-ratio: 3),(min-resolution: 3dppx)
      &
        transform: scaleY(0.33)

  &:first-child:after
    display: none

.rowTitle
  font-weight: bold
  font-size: 18px
  line-height: 20px

.rowCaption
  font-size: 16px
  line-height: 18px
  color: var(--foreground-secondary)
  margin-top: 4px
