.userInfo
  padding: 16px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  overflow: hidden
  flex: 1

.userAvatar
  width: 160px
  height: 160px
  border-radius: 50%
  background: var(--background-tertiary) no-repeat center/cover

.userName
  font-size: 26px
  font-weight: 800
  line-height: 32px
  margin-top: 16px
  text-align: center
  white-space: nowrap
  max-width: 100%
  text-overflow: ellipsis
  overflow: hidden
