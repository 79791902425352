.page
  display: flex
  flex: 1
  flex-direction: column
  min-height: var(--tg-viewport-stable-height)
  box-sizing: border-box
  padding-top: #{env(safe-area-inset-top)}
  padding-bottom: #{env(safe-area-inset-bottom)}
  height: var(--tg-viewport-stable-height)
  max-height: var(--tg-viewport-stable-height)

.onboardingWrap
  display: flex
  flex: 1
  flex-direction: column

.onboardingWarning
  color: var(--hint-color)
  margin-top: 12px
  text-align: center
  font-size: 13px
  line-height: 15px
  padding: 12px 16px
  box-sizing: border-box
