.buttonGroup
  padding: 16px

.button
  margin-top: 16px

  &:first-child
    margin-top: 0

.buttonIn
  border-radius: 16px
  height: 56px
  display: flex
  align-items: center
  padding: 0 16px
  box-sizing: border-box
  background: var(--secondary-bg-color)
  transition: all 150ms ease-in-out

  &:active
    background: var(--background-quaternary)

.text
  font-weight: bold
  font-size: 18px
  line-height: 24px
  flex: 1
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap

.icon
  flex: 0 0 auto
  width: 28px
  height: 28px
  margin-left: 16px
  color: var(--foreground-tertiary)
  display: flex
  align-items: center
  justify-content: center

  svg
    width: 28px
    height: 28px

.indicator
  margin-left: 16px
  color: var(--foreground-tertiary)
  font-size: 18px
  line-height: 24px
