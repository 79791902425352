.date
  color: var(--hint-color)
  height: 40px
  align-items: center
  justify-content: center
  display: flex
  text-align: center
  font-size: 16px
  font-weight: 400
  line-height: 20px

.message
  display: flex
  justify-content: flex-start
  flex: 0 0 auto
  width: 100%
  box-sizing: border-box
  padding: 2px 66px 2px 16px

.heart
  margin: 0 !important
  flex: 0 0 auto

.outbox
  justify-content: flex-end
  padding: 2px 16px 2px 66px

.messageBubble
  padding: 8px 16px
  border-radius: 16px
  background: var(--secondary-bg-color)

.outbox .messageBubble
  background: var(--button-color)

.messageText
  font-size: 17px
  font-weight: 400
  line-height: 24px

.outbox .messageText
  color: var(--button-text-color)
