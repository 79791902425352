.message
  padding: 8px 16px
  background: var(--secondary-bg-color)
  border-radius: 8px
  font-size: 16px
  line-height: 20px
  margin-top: 8px

.actions
  display: flex
  margin-top: 16px

  button
    margin-left: 8px

    &:first-child
      margin-left: 0

.selfie

.selfieImg
  width: 100%

.gender
  padding: 6px 12px
  font-weight: bold
  color: #fff
  background: var(--gradient-blue)
  border-radius: 8px
  display: inline-block
  margin-left: 6px

.genderWoman
  background: pink
  background: var(--gradient-pink)

.refWrap
  background: var(--tint-dislike)
  border-radius: 16px
  overflow: hidden

.otherRefSelfies
  display: flex
  flex-wrap: wrap
  flex-basis: 25px

.otherRefSelfie
  width: 25%
  position: relative

  &:after
    content: ''
    padding-top: 130%
    display: block

.otherRefSelfieImg
  top: 4px
  right: 4px
  bottom: 4px
  left: 4px
  border-radius: 10px
  background: no-repeat center/cover
  position: absolute
