.wrap
  overflow: hidden

.scroll
  overflow-y: scroll
  display: flex
  flex-wrap: nowrap
  padding: 0 8px 24px
  margin-bottom: -24px

