.wrap
  display: flex
  flex-direction: column
  flex: 1
  width: 100%
  margin-top: 0 !important
  padding-top: env(safe-area-inset-top)
  min-height: 100%
  min-height: var(--tg-viewport-stable-height)

  .desktop &
    max-width: 360px + 32px
    margin: 0 auto

.Join__input
  flex: 1
  border: 0
  text-align: center
  font-size: 26px
  line-height: 32px
  background: var(--background-primary)
  color: var(--text-color)
  appearance: none
  margin: 0
  min-height: 64px

  &.birthday
    color: #fff !important
    color: var(--background_content) !important
    caret-color: transparent !important
    cursor: none !important
    position: relative
    z-index: 1

    &::selection
      background: transparent

.genderWrap
  flex: 1
  display: flex
  align-items: center
  justify-content: center
  padding: 16px
  flex-direction: column

.gender
  display: flex
  flex-direction: column
  margin-top: 16px
  padding: 16px 32px
  border-radius: 16px
  background: var(--secondary-bg-color)
  width: 100%
  box-sizing: border-box
  justify-content: center
  align-items: center
  transition: all 150ms ease-in-out

  &:first-child
    margin-top: 0

  &:active
    background-color: var(--background-quaternary)

.genderIcon
  width: 96px
  height: 96px
  background: no-repeat center/contain

.genderWoman
  background-image: url('../../assets/art/woman.png')

.genderMan
  background-image: url('../../assets/art/man.png')

.genderText
  margin-top: 8px
  font-weight: bold
  font-size: 18px
  line-height: 24px
  color: var(--text-color)

.Join__birthday_wrap
  flex: 1
  display: flex
  position: relative
  user-select: none !important

.Join__birthday
  position: absolute
  top: 0
  height: 100%
  left: 0
  width: 100%
  text-align: center
  display: flex
  align-items: center
  justify-content: center
  z-index: 2
  background: #fff
  background: var(--background-primary)
  border: 0
  font-size: 24px
  line-height: 28px

.Join__birthday::placeholder
  color: var(--text-color)

.Join__birthday__item
  width: 24px
  height: 32px
  display: flex
  align-items: center
  justify-content: center
  text-align: center
  font-size: 26px
  line-height: 32px

  &.placeholder
    color: var(--hint-color)


.progressBarWrap
  margin-bottom: 16px

.progressBar
  background: var(--secondary-bg-color)
  height: 4px
  border-radius: 2px
  width: 160px
  margin: 22px auto 0

.progressBarIndicator
  height: 4px
  border-radius: 2px
  background: var(--link-color)
  transition: width 200ms ease-in-out

.progressBarTitle
  margin-top: 38px
  font-weight: 800
  font-size: 26px
  line-height: 32px
  color: var(--text-color)
  padding: 0 32px
  text-align: center

.progressBarCaption
  margin-top: 8px
  font-size: 18px
  line-height: 24px
  color: var(--hint-color)
  padding: 0 32px
  text-align: center

.verifyExample
  padding: 16px

.verifyExampleImg
  width: 100%
  border-radius: 16px
  vertical-align: top
  padding: 0
  margin: 0
  display: block

.verifyExampleLabel
  color: var(--foreground-secondary)
  margin-top: 8px
  font-size: 16px
  line-height: 20px

.verifyExampleCheckWrap
  padding: 16px

.verifyExampleCheck
  display: flex
  width: 100%
  padding-top: 70%
  position: relative
  border-radius: 16px
  overflow: hidden

.verifyExampleCheckImg
  position: absolute
  top: 0
  height: 100%
  width: 50%
  background: no-repeat center/cover

  &:first-child
    left: 0

  &:last-child
    right: 0

.verifyExampleCheckRecapture
  margin-top: 16px
  display: flex
  justify-content: center
