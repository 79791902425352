.item
  flex: 0.5
  box-sizing: border-box
  position: relative
  flex-basis: 50%
  max-width: 50%

  &:after
    content: ''
    display: block
    padding-top: 150%

.mini
  width: 120px
  flex: 0 0 auto

  &:after
    padding-top: 145%

.cont
  position: absolute
  top: 8px
  left: 8px
  right: 8px
  bottom: 8px
  background: var(--background-tertiary)
  border-radius: 16px
  overflow: hidden

.mini .cont
  border-radius: 8px
  right: 0
  bottom: 0

.photo
  z-index: 1
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-size: cover

.blur .photo
  filter: blur(20px)

.info
  padding: 36px 16px 12px
  position: absolute
  left: 0
  box-sizing: border-box
  bottom: 0
  right: 0

  &:after
    content: ''
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)
    position: absolute
    opacity: 0.56
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1

.mini .info
  padding: 24px 8px 6px

.name
  font-size: 17px
  font-weight: 700
  line-height: 24px
  color: #fff
  z-index: 2
  position: relative
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
  max-width: 100%

.mini .name
  font-size: 14px
  line-height: 20px

.fakeName
  width: 72px
  height: 8px
  border-radius: 4px
  opacity: 0.32
  background: #fff
  z-index: 2
  position: relative
