.button
  padding: 12px 16px
  color: #000
  background: #fff
  text-align: center
  z-index: 2
  position: relative
  border-radius: 8px
  font-weight: bold
  flex: 1
  font-size: 16px
  line-height: 20px
  height: 44px

.buttonSecondary
  flex: 0 0 auto
  margin-left: 12px
  display: flex
  align-items: center
  justify-self: center
  background: rgba(255,255,255,0.24)
  color: #fff

.buttons
  display: flex
  margin-top: 12px
