.message
  padding: 8px 16px
  background: var(--secondary-bg-color)
  border-radius: 8px
  font-size: 16px
  line-height: 20px
  margin-top: 8px

.actions
  display: flex
  margin-top: 16px

  button
    margin-left: 8px

    &:first-child
      margin-left: 0
