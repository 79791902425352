.header
  height: 48px
  display: flex
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: 100
  padding: 0 6px 0 16px
  padding-top: #{var(--vkui_internal--safe_area_inset_top)} !important
  align-items: center
  background: var(--bg-color)

  :global(.ios) &
    position: sticky

.vkHeader
  display: flex
  align-items: center

.headerPhoto
  width: 32px
  height: 32px
  background: var(--bg-color) no-repeat center/cover
  border-radius: 50%

.headerCont
  margin-left: 16px
  flex: 1
  overflow: hidden

.headerName
  font-size: 20px
  font-weight: 800
  line-height: 28px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.headerActions
  flex: 0 0 auto
  margin-left: 16px
  display: flex

.headerAction
  height: 48px
  width: 48px
  color: var(--link-color)
  display: flex
  align-items: center
  justify-content: center

  svg
    width: 28px
    height: 28px

.history
  padding: 48px + 20px 0 56px + 6px
  //padding: 20px 0 6px
  display: flex
  flex-direction: column
  justify-content: flex-end
  flex: 1
  box-sizing: border-box

  :global(.ios) &
    padding-bottom: 6px

.sendForm
  //position: fixed
  //bottom: 0
  //left: 0
  //right: 0
  //z-index: 100
  background: var(--bg-color)
  display: flex
  align-items: flex-end
  padding: 4px
  padding-bottom: #{var(--vkui_internal--safe_area_inset_bottom)}

  :global(.ios) &
    position: relative

.sendFormInputWrap
  flex: 1
  margin: 0 4px
  display: flex
  align-items: center
  min-height: 48px

.sendFormInput
  font-size: 17px
  font-weight: 400
  line-height: 24px
  padding: 8px 16px
  border-radius: 20px
  border: 0
  background: var(--secondary-bg-color)
  box-sizing: border-box
  width: 100%
  flex: 1
  outline: none
  resize: none
  min-height: 40px
  height: 40px
  max-height: 136px

.sendFormInput::placeholder
  color: var(--hint-color)

.sendFormBtn
  width: 48px
  height: 48px
  display: flex
  align-items: center
  justify-content: center
  color: var(--link-color)
  position: relative

.sendFormBtnLike
  color: var(--tint-dislike)

.btnIcon
  position: absolute
  top: 10px
  left: 10px
  opacity: 0
  transform: scale(0.1)
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out

.btnIconVisible
  opacity: 1
  transform: scale(1)

.matchPhoto
  width: 128px
  height: 128px
  background: var(--bg-color) no-repeat center/cover
  border-radius: 50%

.danger
  padding: 8px 12px
  font-size: 14px
  line-height: 18px
  border: 1px solid var(--tint-dislike)
  border-radius: 8px
  margin: 0 16px
